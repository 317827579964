import React from "react";

import Page from "../components/Page";

const NotFoundPage = () => (
  <Page seoTitle="404: Not found">
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "auto"
      }}
    >
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
      <a href="https://www.hardmoneychoices.com">Try This</a>
    </div>
  </Page>
);

export default NotFoundPage;
